import * as React from "react"
import Header from "../components/Header";
import {SEO} from "../components/seo";
import {Link} from "gatsby";
import {Helmet} from "react-helmet";


const ErrorPage = ({data}) => {

    return (
        <div>
            <Helmet>
                <title>Inter Protocol - 404</title>
            </Helmet>
            <Header/>
            <div className={'error-page pt-40'}>
                <div className={'section'}>
                    <div className={'container container-slim text-center'}>
                        <div className={'error-code'}>404</div>

                        <h4 className={'mb-7'}>whoops... this page is not available</h4>

                        This page dowsn't exist or was removed!

                        <Link to={'/'}>
                            <button className={'mt-14 table mx-auto'}>Back to Homepage</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage

export const Head = () => (
    <SEO />
)
